import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Text, Intent, Alert } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import patientsService from '../../api-services/patients-service'
import organizationsService from '../../api-services/organizations-service'
import { useList } from '../custom-hooks/api-hooks'
import { serverErrorToast, successToast } from '../layout/toaster/toaster'
import FormElement from '../commons/form-group/form-group'
import css from './patients.module.css'

const statuses = [
  { label: 'CueCare Plus', value: 'plus' },
  { label: 'Regular', value: 'regular' }
]

const patientClinics = [
  { label: 'None', value: '0' },
  { label: 'RAAC (British Columbia)', value: '1' },
  { label: 'RSRC (New Brunswick)', value: '2' },
  { label: 'CAMH (Ontario)', value: '3' },
  { label: 'CRCHUM (Quebec)', value: '4' },
  { label: 'Prairies', value: '5' },
  { label: 'Clinic Ref 1', value: '6' },
  { label: 'Clinic Ref 2', value: '7' },
  { label: 'Clinic Ref 3', value: '8' }
]

const patientTypes = [
  { label: 'normal', value: 'normal' },
  { label: 'lab', value: 'lab' }
]

function PatientForm({ setDialogOpen, patient }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState()
  const [isDeleteDialogOpen, setIsDeleteOpen] = useState(false)
  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: patient ? {
      firstName: patient.firstName,
      lastName: patient.lastName,
      address: patient.address,
      phone: patient.phone,
      email: patient.email,
      timezone: { label: patient.timezone, value: patient.timezone },
      gatewayId: patient.gatewayId,
      dateOfBirth: moment(patient.dateOfBirth).toDate(),
      gender: { label: patient.gender, value: patient.gender },
      status: statuses.find(({ value }) => value === patient.status),
      salesforceId: patient.salesforceId,
      subscriptionStartDate: patient.subscriptionStartDate,
      patientType: patientTypes.find(({ value }) => value === patient.patientType),
      patientClinic: patientClinics.find(({ value }) => value === patient.patientClinic),
      pharmacy: patient.pharmacy ? { label: patient.pharmacy.name, value: patient.pharmacy } : null,
      hca: patient.hca ? { label: patient.hca.name, value: patient.hca } : null
    } : {}
  })
  useList(organizationsService, setOrganizations)

  if (!organizations) return <Text>Loading...</Text>

  async function deletePatient() {
    try {
      await patientsService.delete(patient._id)
      successToast(`Patient was successfully removed`)
      patientsService.populate()
      history.push('/patients')
    } catch (err) {
      serverErrorToast(err)
      setIsDeleteOpen(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const { email, firstName, lastName, address, phone, dateOfBirth, gatewayId, salesforceId, subscriptionStartDate, patientType, patientClinic } = data

    const body = {
      gatewayId,
      email,
      firstName,
      lastName,
      address,
      phone,
      salesforceId,
      subscriptionStartDate,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      timezone: data.timezone.value,
      pharmacyId: data.pharmacy ? data.pharmacy.value._id : null,
      hcaId: data.hca ? data.hca.value._id : null,
      gender: data.gender.value,
      status: data.status ? data.status.value : null,
      patientType: data.patientType ? data.patientType.value : null,
      patientClinic: data.patientClinic ? data.patientClinic.value : null,
    }

    try {
      if (patient) await patientsService.patch(patient._id, body)
      else await patientsService.post(body)
      patientsService.populate()

      successToast(`Patient data was saved.`)
      setLoading(false)
      setDialogOpen(false)
    } catch (err) {
      serverErrorToast(err)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bp3-dialog-header'>
        <span className='bp3-icon-large bp3-icon-inbox' />
        <h4 className='bp3-heading'>{patient ? 'Edit Patient' : 'Add Patient'}</h4>
        <button onClick={() => setDialogOpen(false)} type='button' aria-label='Close' className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross' />
      </div>
      <div className='bp3-dialog-body'>
        <FormElement name='pharmacy' control={control} rules={{ required: true }} label='Pharmacy' options={organizations.map(i => { return { label: i.name, value: i } })} errors={errors} />
        <FormElement name='patientType' control={control} rules={{ required: true }} label='PatientType' options={patientTypes} errors={errors} />
        <FormElement name='patientClinic' control={control} rules={{ required: true }} label='Clinic Name' options={patientClinics} errors={errors} />
        <FormElement name='hca' control={control} label='HomeCare Agency' isClearable options={organizations.map(i => { return { label: i.name, value: i } })} errors={errors} />
        <FormElement name='gatewayId' inputRef={register()} label='Gateway ID' errors={errors} />
        <FormElement name='timezone' isClearable rules={{ required: true }} control={control} options={moment.tz.names().map(i => { return { label: i, value: i } })} label='Timezone' errors={errors} />
        <div className={css.row}>
          <div className={css.col}>
            <FormElement name='status' control={control} label='CueCare Plus' options={statuses} errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Email' inputRef={register({ pattern: /\S+@\S+/ })} name='email' errors={errors} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='First Name' inputRef={register({ required: true })} name='firstName' errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Last Name' inputRef={register({ required: true })} name='lastName' errors={errors} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='Gender' control={control} rules={{ required: true }} name='gender' options={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]} errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Date Of Birth' control={control} rules={{ required: true }} name='dateOfBirth' type='date' showYearDropdown scrollableYearDropdown yearDropdownItemNumber={80} maxDate={new Date()} errors={errors} />
          </div>
        </div>
        <FormElement label='Phone Number' labelInfo='+1 (XXX) XXX-XXXX' inputRef={register} name='phone' errors={errors} />
        <FormElement label='Address' inputRef={register} name='address' errors={errors} />
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='Salesforce ID' inputRef={register} name='salesforceId' errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Prescription Start Date' inputRef={register} name='subscriptionStartDate' errors={errors} />
          </div>
        </div>
      </div>
      <div className='bp3-dialog-footer'>
        <div className='bp3-dialog-footer-actions' style={patient && { justifyContent: 'space-between' }}>
          {patient && <Button icon='trash' intent={Intent.DANGER} text='Delete' style={{ margin: 0 }} onClick={() => setIsDeleteOpen(true)} />}
          <div>
            <Button onClick={() => setDialogOpen(false)} type='button'>Cancel</Button>
            <Button intent={Intent.SUCCESS} type='submit' icon='saved' loading={loading} >Save</Button>
          </div>
        </div>
      </div>
      {patient && <Alert
        cancelButtonText='Cancel'
        confirmButtonText='Delete Patient'
        icon='trash'
        intent={Intent.DANGER}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteOpen(false)}
        onConfirm={deletePatient}
      >
        <p>Are you sure you want to permanently delete {patient.firstName} {patient.lastName} and all related data?</p>
      </Alert>}
    </form>
  )
}

export default PatientForm
